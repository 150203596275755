body.contact {

  .form {

    width: 308px;
    margin: 0 auto;

    .input-group {
      margin-bottom: 15px;
      overflow: auto; }

    .form-control {
      height: 38px;
      border-radius: 4px;
      font-size: 12px; }
    textarea.form-control {
      height: 88px; }

    label {
      display: block; }

    .form-label {
      text-align: center;
      font-weight: normal;
      font-size: 15px;
      margin-bottom: 5px; }


    .g-recaptcha {
      position: relative;
      left: 2px; }

    .submit {
      @include spritebutton(97px, 30px, '/images/button_send.png');
      float: right; }


    // file input
    .fileinput {
      display: flex;
      margin: 0;
      .form-control {
        width: 230px; }
      .btn {
        padding: 0;
        @include spritebutton(63px, 30px, '/images/button_search.png');
        left: 9px;
        top: 3px; } }
    .fileinput-filename {
      position: relative;
      top: 2px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left; } } }


.focus-element.focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6); }

.has-error .g-recaptcha {
  border: 1px solid #a94442;
  border-radius: 4px; }
