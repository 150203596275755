body.legal {
  h2 {
    text-align: center; }
  .box {
    width: 860px;
    text-align: left; }
  .hr {
    width: 385px; }
  .button {
    margin: 10px 0 30px; } }
