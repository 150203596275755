$red: #c8102e;
$footer-background: #1a1a1b;
$placeholder-color: #a8a8a8;
$box-border-color: #cbcbcb;
$title-color: #484848;
$text-color: #767676;
$min-width: 900px;



@mixin spritebutton($width, $height, $src) {
  height: $height;
  width: $width;
  background: transparent url($src) no-repeat;
  background-size: $width, $height * 3;
  cursor: pointer;
  text-indent: -9999px;
  display: inline-block;
  border-radius: 0;
  border: 0;
  &:hover, &.hover, &:focus {
    background-position: 0 #{-$height}; }
  &:active, &.active {
    background-position: 0 #{-2 * $height}; } }
