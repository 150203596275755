body.home {
  #content {
    height: 100%;
    background: white;
    justify-content: center; } }

.home-container {
  width: 570px;
  text-align: center;
  &__logo {
    width: 100%; }
  &__button {
    @include spritebutton(154px, 40px, '/images/button_home.png');
    margin: 70px 0 100px; } }
