.success-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;

  &__box {
    box-sizing: border-box;
    width: 480px;
    border: 1px solid $box-border-color;
    border-radius: 4px;
    background: white;
    padding: 100px 80px;
    text-align: center;
    position: relative; }

  &__title {
    font-size: 30px; }

  &__text {
    font-size: 11px; }

  &__close {
    position: absolute;
    right: -10px;
    top: -10px; } }
