@import 'include';

@import 'components/hr';
@import 'components/successOverlay';

@import 'pages/home';
@import 'pages/legal';
@import 'pages/impressum';
@import 'pages/contact';




html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0; }

body {
  font-family: Lato, sans-serif;
  background: url('/images/bg_main.png');
  color: $text-color; }

p {
  margin-bottom: 1.5em; }

h1, h2 {
  color: $title-color; }

.center {
  text-align: center; }
.float-right {
  float: right; }
.float-left {
  float: left; }

.has-placeholder:empty:before {
  content: attr(data-placeholder);
  display: inline-block;
  color: $placeholder-color; }



::-webkit-input-placeholder {
  color: $placeholder-color !important;
  font-weight: normal; }
::-moz-placeholder {
  color: $placeholder-color !important;
  font-weight: normal; }
:-moz-placeholder {
  color: $placeholder-color !important;
  font-weight: normal; }
:-ms-input-placeholder {
  color: $placeholder-color !important;
  font-weight: normal; }


#header {
  background: white;
  height: 154px;
  min-width: $min-width;
  display: flex;
  align-items: center;
  justify-content: center;
  .logo {
    img {
      height: 95px; } }
  border-bottom: 2px solid $red;
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75); }



#footer {
  background: black;
  color: #ddd;
  font-size: 12px;
  height: 44px;
  min-width: $min-width;
  position: fixed;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  width: 100%;
  flex-wrap: wrap;
  .left {
    flex: 2;
    text-align: left;
    margin-left: 125px;
    white-space: nowrap; }
  .right {
    flex: 1;
    text-align: right;
    margin-right: 125px;
    white-space: nowrap; }
  a {
    color: #ddd;
    text-decoration: none; } }


#content {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: $min-width;
  font-size: 11px; }



.box {
  width: 480px;
  margin-top: 37px;
  margin-bottom: 80px;
  box-sizing: border-box;
  background: white;
  border: 1px solid $box-border-color;
  border-radius: 6px;
  padding: 0 27px;
  text-align: center;
  h2 {
    font-size: 29px;
    font-weight: normal;
    color: $title-color;
    width: 100%;
    margin-bottom: 0; }
  .button {
    @include spritebutton(154px, 40px, '/images/button_back.png');
    margin: 20px 0 40px; } }



